
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import CiTable, {
    ITableData, ITableConfig, ICell, DATA_TYPE, IIndicators,
} from '@/modules/common/components/ci-table';

import type HelperService from '@/modules/common/services/helper.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import { KEY } from '@/inversify.keys';
import type ProvidersService from '@/modules/providers/providers.service';
import CalendarEventsContainer from '@/modules/events/components/calendar-events-container.vue';
import HotelRooms from '@/modules/common/interfaces/hotelRooms.interface';
import PriceFilter from '@/modules/common/filters/price.filter';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import type DocumentFiltersService from '@/modules/document-filters/document-filters.service';
import type UserService from '@/modules/user/user.service';
import type HotelsService from '@/modules/hotels/hotels.service';
import type RatesService from '@/modules/rates/rates.service';
import type CompsetsService from '@/modules/compsets/compsets.service';
import type Day from '@/modules/common/types/day.type';

import RatesDocumentItemModel from '../../models/rates-document-item.model';
import RatesDocumentModel from '../../models/rates-document.model';
import RatesTableTooltip from './table-tooltip.vue';
import PRICE_SHOWN from '../../constants/price-shown.constant';
import RatesAllService, { RatesAllServiceS } from '../../rates-all.service';

const BOOKING_BASIC_ICON = require('@/modules/common/assets/booking-basic.svg');

interface RatesTableTooltipData {
    day: Day;
    focusElement: HTMLElement | null;
    hotelId: number | string;
    previewValues: { [k: string]: any };
    indicators: IIndicators;
}

@Component({
    components: {
        CiTable,
        RatesTableTooltip,
    },
    filters: {
        PriceFilter,
    },
})
export default class RatesTable extends Vue {
    @inject(KEY.RatesService) private ratesService!: RatesService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.DocumentFiltersService) private documentFiltersService!: DocumentFiltersService;
    @inject(KEY.HelperService) private helperService!: HelperService;
    @inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(RatesAllServiceS) private ratesAllService!: RatesAllService;
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;

    disabledColumns: string[] = [];
    tooltipDay: Day = 1;
    tooltipFocusElement: HTMLElement | null = null;

    tooltipData: RatesTableTooltipData = {
        day: 1,
        focusElement: null,
        hotelId: 0,
        previewValues: {},
        indicators: {
            mealType: false,
            numberOfGuests: false,
            cancellation: false,
            losRestriction: false,
            occupancy: false,
        },
    };

    mounted() {
        this.scrollToActiveRow();
    }

    transformDate(day: number): string {
        const { month, year } = this.documentFiltersService.storeState.settings;
        const d = new Date(year, month, day);
        const weekDay = this.$tc(`dayShort.${d.getDay()}`);
        return `${weekDay.charAt(0) + weekDay.slice(1).toLowerCase()} ${day}/${month + 1 < 10 ? 0 : ''}${month + 1}`;
    }

    getRoom(day: Day, hotelId: number | string) {
        return this.ratesService.getRoom(day, hotelId);
    }

    demand(day: Day) {
        const demandValue = this.ratesService.getDemand(day);
        if (demandValue === null) return '';
        return demandValue;
    }

    occupancy(day: Day) {
        const occupancyValue = this.ratesService.getOccupancy(day);
        if (occupancyValue === null) return '';
        return occupancyValue;
    }

    medianPrice(day: Day) {
        const price = this.ratesService.isAllChannelsMode
            ? this.ratesAllService.getAverageRoomsPrice(day)
            : this.ratesService.getMedianPrice(day);
        return price ? PriceFilter(price) : '';
    }

    myHotelPrice(day: Day) {
        if (this.ratesService.isLoading) return null;

        if (this.ratesService.isAllChannelsMode) {
            return null;
        }

        const { currentHotelId } = this.userService;
        const { data } = this.ratesService;

        if (day && currentHotelId) {
            const { exchangeRate } = (data || {}) as RatesDocumentModel;

            return this.ratesService.getPrice(day, currentHotelId)! * exchangeRate;
        }
        return null;
    }

    getDiff(day: Day) {
        const compsetPrice = this.ratesService.getCompsetPrice(day);
        const myHotelPrice = this.myHotelPrice(day);

        if (!myHotelPrice || !compsetPrice) {
            return {
                value: '',
                color: '',
            };
        }

        const isBadColor = this.ratesService.getTableAssessment(compsetPrice, day) === ASSESSMENTS_TYPES.BAD;
        const result = {
            value: '',
            color: isBadColor ? 'red' : 'grey',
        };

        if (this.showPriceDiff) {
            const diffInNumber = Math.abs(myHotelPrice - compsetPrice);
            result.value = diffInNumber ? `${this.currency} ${PriceFilter(diffInNumber, 0)}` : '';

            return result;
        }

        const diffInPercent = this.ratesService.getCompetitionPercent(day);
        result.value = diffInPercent ? `${Math.round(diffInPercent * 100)}%` : '';

        return result;
    }

    priceColor(day: Day, price: number) {
        const assessmentType = price ? this.ratesService.getTableAssessment(price, day) : null;

        if (assessmentType === ASSESSMENTS_TYPES.BAD) {
            return '#E7472D';
        }

        if (assessmentType === ASSESSMENTS_TYPES.GOOD) {
            return '#01B875';
        }

        return null;
    }

    getRoomData(day: Day, rooms: HotelRooms, hotelId: number | string) {
        if (!rooms[hotelId]) return null;

        const hotelData = this.ratesService.isAllChannelsMode
            ? this.ratesAllService.getCheckinDay(day)?.[hotelId]
            : this.ratesService.getCheckinDay(day)?.hotels[+hotelId];

        const data = {
            rank: '',
            price: '',
            priceTitle: undefined as string | undefined,
            style: {},
            hasPrice: false,
            isBasic: false,
            isNetCalc: false,
            isTotalCalc: false,
        };

        if (this.ratesService.isOutOfRange()) {
            data.price = this.$tc('outOfRange');
            return data;
        }

        if (this.ratesService.isNoData(day)) {
            data.price = this.$tc('noData');
            return data;
        }

        if (this.ratesService.isNA(day, hotelId)) {
            data.price = this.$tc('na');
            return data;
        }

        if (this.ratesService.isSoldOut(day, hotelId)) {
            if (hotelId !== 'average') {
                data.price = hotelData?.pax
                    ? this.$tc('nog', 0, [hotelData?.pax])
                    : this.$tc('soldOut');

                data.priceTitle = hotelData?.pax
                    ? this.$tc('rates.nogAvailable', 0, [hotelData?.pax])
                    : undefined;
                return data;
            }

            data.price = this.$tc('soldOut');
            return data;
        }

        const room = rooms[hotelId];
        const price = this.ratesService.switchPrice(rooms[hotelId]) as number;
        const isNet = this.documentFiltersService.priceShown === PRICE_SHOWN.NET;
        const isTotal = this.documentFiltersService.priceShown === PRICE_SHOWN.TOTAL;

        data.hasPrice = true;
        data.price = PriceFilter(price);
        data.rank = room ? `(${String(room!.priceRank || '')})` : '';
        data.style = { color: this.priceColor(day, price) };
        data.isBasic = !!room && room.isBasic;
        data.isNetCalc = isNet && !!room?.isNetCalc;
        data.isTotalCalc = isTotal && !!room?.isTotalCalc;

        return data;
    }

    losRestriction(day: Day, hotelId: number | string) {
        const isSoldOut = this.ratesService.isSoldOut(day, hotelId);
        const isNA = this.ratesService.isNA(day, hotelId);

        if (isSoldOut || isNA) {
            return false;
        }

        const losRestriction = this.ratesService.getHotelLosRestriction(day, hotelId);
        if (!losRestriction) {
            return false;
        }

        return losRestriction;
    }

    indicators(day: Day, hotelId: number | string, hotelRooms: HotelRooms) {
        const indicators: IIndicators = {
            mealType: false,
            numberOfGuests: false,
            cancellation: false,
            losRestriction: false,
            occupancy: false,
        };

        const room = hotelRooms[hotelId];

        if (!room) {
            return indicators;
        }

        const mealType = this.mealTypesService.getMealType(room.mealTypeId) || null;

        const hasMealType = this.ratesService.hasRoomMealType(day, hotelId);
        if (hasMealType) {
            indicators.mealType = {
                name: this.$tc('titles.mealType'),
                value: mealType ? this.$tc(mealType.displayName) : '',
                icon: 'icon-meal-3',
            };
        }

        const hasOccupancy = !this.ratesService.hasRoomSameOccupancy(day, hotelId);
        if (hasOccupancy) {
            indicators.occupancy = {
                name: this.$tc('titles.numberOfGuests'),
                value: String(room.occupancy),
                icon: 'icon-Guest-2-01',
            };
        }

        const hasCancelation = this.ratesService.hasRoomMultipleCancellation(day, hotelId);
        if (hasCancelation) {
            indicators.cancellation = {
                name: this.$tc('titles.cancellation'),
                value: this.$tc('rates.multiple'),
                icon: 'icon-cancelation2',
            };
        }
        const hasLosRestriction = this.losRestriction(day, hotelId);
        if (hasLosRestriction) {
            indicators.losRestriction = {
                name: this.$tc('titles.losRestriction'),
                value: String(hasLosRestriction),
                icon: 'icon-los2',
            };
        }

        return indicators;
    }

    handleRowClick(day: Day) {
        if (this.userService.currentHotelId) {
            const hotelId = String(this.userService.currentHotelId);

            this.$router.push({
                name: `${this.$route.name!}.day-rate`,
                params: { hotelId, day: String(day) },
            });
        }
    }

    setTooltipData(day: Day, focusElement: HTMLElement) {
        this.tooltipFocusElement = focusElement;
        this.tooltipDay = day;
    }

    resetTooltipData() {
        this.tooltipFocusElement = null;
    }

    updateDisabledColumnsList(disabledColumns: string[]) {
        this.disabledColumns = disabledColumns;
    }

    generatePreviewData(day: Day) {
        const Events = this.$tc('titles.events');
        const Demand = this.$tc('titles.demand');
        const OTB = this.$tc('titles.otb');
        const Median = this.$tc('compsetRate.median');
        const Diff = this.$tc('titles.diff');

        const params = {
            [Events]: () => ({
                component: CalendarEventsContainer,
                props: {
                    day,
                    emptyText: this.$tc('rates.noevents'),
                },
            }),
            [Demand]: () => {
                const demand = this.demand(day);

                return {
                    value: demand ? `${demand}%` : this.$tc('noData'),
                };
            },
            [OTB]: () => {
                const occupancy = this.occupancy(day);

                return {
                    value: occupancy ? `${occupancy}%` : this.$tc('noData'),
                };
            },
            [Median]: () => {
                const median = this.medianPrice(day);
                const value = median
                    ? this.currency + median
                    : this.$tc('noData');

                return {
                    value,
                };
            },
            [Diff]: () => ({
                value: this.getDiff(day).value,
            }),
        } as {
            [k: string]: () => {
                component?: any;
                props?: { [k: string]: any };
                value?: any,
            }
        };

        const entries = this.disabledColumns.map(key => {
            const value = params[key]?.();
            return [key, value];
        });

        return Object.fromEntries(entries);
    }

    get isOutOfRange() {
        return this.ratesService.isOutOfRange();
    }

    get isLoading() {
        const state = this.ratesService.isLoading;

        if (!state) {
            this.scrollToActiveRow();
        }

        return state;
    }

    get hotelId() {
        return +this.$route.params.hotelId || this.userService.currentHotelId!;
    }

    get showPriceDiff() {
        return this.ratesService.showDiff;
    }

    get currency(): string | null {
        const { currency } = this.ratesService;
        return currency ? this.helperService.currencySymbol(currency) : '';
    }

    get ratesTableConfig(): ITableConfig {
        const isCheapest = this.ratesService.settings.provider === 'cheapest';

        return {
            height: '100%',
            width: '100%',
            cellSize: [{
                width: !this.ratesService.isAllChannelsMode
                    ? ['90px', '120px', '120px', '120px', '120px', '120px', '200px']
                    : ['90px', '120px', '150px'],
                height: ['50px'],
            }, {
                width: [isCheapest ? '250px' : '200px'],
                height: ['50px'],
            }],
        };
    }

    get ratesTableData(): ITableData {
        const { currentHotelId } = this.userService;
        const competitors: (string | number)[] = this.ratesService.isAllChannelsMode
            ? this.compsetsService.currentCompset?.rateProviders || []
            : this.documentFiltersService.competitors || [];

        const { days } = this.documentFiltersService;

        if (!currentHotelId) {
            return [];
        }

        const NO_DATA_LABEL = this.ratesService.isOutOfRange()
            ? this.$tc('outOfRange')
            : this.$tc('noData');

        const rooms: HotelRooms[] = days.map(day => {
            if (this.ratesService.isAllChannelsMode) {
                const rooms = this.ratesService.getCompetitorsRooms(day);

                return rooms;
            }

            const hotelRooms = competitors
                .reduce((acc, competitor) => {
                    const room = this.getRoom(day, competitor);
                    if (!room) return acc;

                    acc[competitor] = room;
                    return acc;
                }, {} as Record<string | number, RatesDocumentItemModel>);

            const room = this.getRoom(day, currentHotelId);

            if (room) {
                hotelRooms[currentHotelId] = room;
            }

            return hotelRooms;
        });

        const handleCellHover = (day: Day, hotelId: number | string = currentHotelId) => (e: MouseEvent) => {
            const focusElement = e.currentTarget! as unknown as HTMLElement;

            this.tooltipData = {
                ...this.tooltipData,

                indicators: this.indicators(day, hotelId, rooms[day - 1]),
                hotelId,
                previewValues: hotelId === currentHotelId
                    ? this.generatePreviewData(day)
                    : {},
            };

            this.setTooltipData(day, focusElement);
        };

        return [
            {
                isSticky: true,
                boldRow: this.documentFiltersService.todayDate,
                columns: [
                    {
                        title: this.$tc('titles.date'),
                        data: days.map(day => ({
                            value: this.transformDate(day),
                            onClick: () => this.handleRowClick(day),
                        })),
                    }, {
                        title: this.$tc('titles.events'),
                        dataType: DATA_TYPE.EVENT,
                        data: days.map(day => ({
                            onClick: () => this.handleRowClick(day),
                        })),
                    },
                    !this.ratesService.isAllChannelsMode
                        ? {
                            title: this.$tc('titles.demand'),
                            dataType: DATA_TYPE.DEMAND,
                            data: days.map(day => {
                                const demand = this.demand(day);
                                return {
                                    value: demand ? `${demand}%` : '',
                                    onClick: () => this.handleRowClick(day),
                                };
                            }),
                        } : null,
                    !this.ratesService.isAllChannelsMode
                        ? {
                            title: this.$tc('titles.otb'),
                            dataType: DATA_TYPE.OCCUPANCY,
                            data: days.map(day => {
                                const occupancy = this.occupancy(day);
                                return {
                                    value: occupancy ? `${occupancy}%` : '',
                                    onClick: () => this.handleRowClick(day),
                                };
                            }),
                        } : null,
                    {
                        title: this.ratesService.isAllChannelsMode
                            ? this.$tc('compsetRate.average')
                            : this.$tc('compsetRate.median'),
                        data: days.map(day => {
                            const median = this.medianPrice(day);
                            return {
                                value: median ? `${this.currency} ${median}` : '',
                                onClick: () => this.handleRowClick(day),
                            };
                        }),
                    },
                    !this.ratesService.isAllChannelsMode
                        ? {
                            title: this.$tc('titles.diff'),
                            data: days.map(day => {
                                const { value, color } = this.getDiff(day);
                                return {
                                    value,
                                    style: { color },
                                    onClick: () => this.handleRowClick(day),
                                };
                            }),
                        }
                        : null as any,
                    !this.ratesService.isAllChannelsMode
                        ? {
                            title: this.hotelsService.hotelNames[currentHotelId],
                            titleStyle: { color: '#00759e' },
                            dynamicColumns: [
                                this.$tc('titles.events'),
                                this.$tc('titles.demand'),
                                this.$tc('titles.otb'),
                                this.$tc('compsetRate.median'),
                                this.$tc('titles.diff'),
                            ],
                            data: days.map(day => {
                                const {
                                    rank, price, hasPrice,
                                    isBasic, isNetCalc, isTotalCalc,
                                    priceTitle,
                                } = this.getRoomData(day, rooms[day - 1], currentHotelId) || {
                                    rank: null,
                                    price: this.ratesService.isNoData(day) ? NO_DATA_LABEL : this.$tc('na'),
                                    hasPrice: false,
                                    isBasic: false,
                                };

                                const indicators = this.indicators(day, currentHotelId, rooms[day - 1]);
                                const provider = this.ratesService.settings.provider === 'cheapest' && hasPrice
                                    ? this.ratesService.getRoomProviders(day, currentHotelId)
                                    : undefined;

                                let netTotalCalcTitle = isNetCalc ? this.$tc('rates.priceIsNetCalculated') : undefined;
                                netTotalCalcTitle = isTotalCalc ? this.$tc('rates.priceIsTotalCalculated') : undefined;

                                const titleArg = priceTitle || netTotalCalcTitle;
                                const isNetTotalCalc = isNetCalc || isTotalCalc;

                                return {
                                    value: `${hasPrice ? `${this.currency} ` : ''}${price}${isNetTotalCalc ? '*' : ''}`,
                                    titleArg,
                                    before: rank,
                                    provider,
                                    indicators,
                                    img: isBasic ? BOOKING_BASIC_ICON : null,
                                    onClick: () => this.handleRowClick(day),
                                    onHover: handleCellHover(day),
                                    onLeave: this.resetTooltipData.bind(this),
                                } as ICell;
                            }),
                        }
                        : null as any,
                ].filter(Boolean),
            },
            {
                boldRow: this.documentFiltersService.todayDate,
                columns: competitors.map(competitorId => ({
                    title: this.ratesService.isAllChannelsMode
                        ? this.providersService.getProviderLabel(String(competitorId))
                        : this.hotelsService.hotelNames[competitorId],
                    data: days.map(day => {
                        const {
                            rank, price, style, hasPrice,
                            isBasic, isNetCalc, priceTitle,
                            isTotalCalc,
                        } = this.getRoomData(day, rooms[day - 1], competitorId) || {
                            rank: null,
                            price: this.ratesService.isNoData(day) ? NO_DATA_LABEL : this.$tc('na'),
                            style: null,
                            hasPrice: false,
                            isBasic: false,
                        };
                        const indicators = this.indicators(day, competitorId, rooms[day - 1]);
                        const provider = this.ratesService.settings.provider === 'cheapest' && hasPrice
                            ? this.ratesService.getRoomProviders(day, +competitorId)
                            : undefined;

                        let netTotalCalcTitle = isNetCalc ? this.$tc('rates.priceIsNetCalculated') : undefined;
                        netTotalCalcTitle = isTotalCalc ? this.$tc('rates.priceIsTotalCalculated') : undefined;

                        const titleArg = priceTitle || netTotalCalcTitle;
                        const isNetTotalCalc = isNetCalc || isTotalCalc;

                        return {
                            value: `${hasPrice ? `${this.currency} ` : ''}${price}${isNetTotalCalc ? '*' : ''}`,
                            titleArg,
                            before: rank,
                            provider,
                            img: isBasic ? BOOKING_BASIC_ICON : null,
                            indicators,
                            style,
                            onClick: () => this.handleRowClick(day),
                            onHover: handleCellHover(day, competitorId),
                            onLeave: this.resetTooltipData.bind(this),
                        } as ICell;
                    }),
                    isFolding: true,
                })),
            },
        ];
    }

    scrollToActiveRow() {
        const { table } = this.$refs as { table: CiTable };
        const { todayDate } = this.documentFiltersService;

        if (table && todayDate) {
            table.scrollToRow(todayDate);
        }
    }
}
